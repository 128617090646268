<template>
    <div class="class">
        <el-dialog v-model="dialogFormVisible" :title="title" width="50%" @close="$emit('close')">
            <el-form :model="form" :disabled="title == '查看指标'" class="form" :rules="rules" ref="ruleForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="指标名称：" prop="indexName" :label-width="formLabelWidth">
                            <el-input placeholder="请输入" v-model="form.indexName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有数源应用：" prop="whetherSource" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.whetherSource" class="ml-4" @change="sourceChange">
                                <el-radio label="0" size="large">是</el-radio>
                                <el-radio label="1" size="large">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item
                            v-if="form.whetherSource == 0"
                            label="数源应用名称："
                            prop="sourceName"
                            :label-width="formLabelWidth"
                        >
                            <el-input placeholder="请输入" v-model="form.sourceName"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否有目标值：" prop="indexType" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.indexType" @change="radioChange">
                                <el-radio label="0" size="large">是</el-radio>
                                <el-radio label="1" size="large">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            v-if="form.indexType == 0"
                            label="目标值："
                            prop="indexNum"
                            :label-width="formLabelWidth"
                        >
                            <el-select
                                v-model="form.indexNumSymbol"
                                class="select-input"
                                placeholder="请选择"
                                @change="selectChange"
                            >
                                <el-option :key="0" label=">" :value="0" />
                                <el-option :key="1" label="≥" :value="1" />
                                <el-option :key="2" label="<" :value="2" />
                                <el-option :key="3" label="≤" :value="3" />
                                <el-option :key="4" label="=" :value="4" />
                            </el-select>
                            <el-input
                                placeholder="请输入"
                                type="number"
                                class="tagertInput"
                                v-model="form.indexNum"
                            ></el-input>
                        </el-form-item> </el-col
                ></el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="指标是否涉密：" prop="indexSecret" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.indexSecret" @input="groupInput">
                                <el-radio label="0" size="large">是</el-radio>
                                <el-radio label="1" size="large">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="envType === 'intranet' && form.indexSecret === '0'">
                        <el-form-item label="密级：" prop="classification" :label-width="formLabelWidth">
                            <el-select v-model="form.classification" class="select-input" placeholder="请选择">
                                <el-option
                                    v-for="(item, index) in classificationMap"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" v-if="form.indexType == 0">
                        <div v-for="(item, index) in form.unitList" :key="index" class="unit-label">
                            <div
                                class="icon"
                                v-if="form.unitList.length > 1 && title !== '查看指标'"
                                @click="deleteDepart(index)"
                            ></div>
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item
                                        :label="'责任单位' + `${index + 1}` + '：'"
                                        :key="index"
                                        :prop="'unitList.' + index + '.orgIdList'"
                                        :label-width="formLabelWidth"
                                        :rules="rules.orgIdList"
                                    >
                                        <el-cascader
                                            @change="handleChange(index, $event)"
                                            ref="cascader"
                                            filterable
                                            clearable
                                            v-model="item.orgIdList"
                                            :props="defaultProps"
                                        />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item
                                        :label="'目标值' + `${index + 1}` + '：'"
                                        :key="index"
                                        :prop="'unitList.' + index + '.orgNum'"
                                        :label-width="formLabelWidth"
                                        :rules="form.indexType == 1 ? rules.noIndexType : rules.indexType"
                                        class="tagertNum"
                                    >
                                        <el-select
                                            v-model="item.unitNumSymbol"
                                            class="select-input"
                                            placeholder="请选择"
                                            disabled
                                        >
                                            <el-option :key="0" label=">" :value="0" />
                                            <el-option :key="1" label="≥" :value="1" />
                                            <el-option :key="2" label="<" :value="2" />
                                            <el-option :key="3" label="≤" :value="3" />
                                            <el-option :key="4" label="=" :value="4" />
                                        </el-select>
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            class="tagertInput"
                                            v-model="item.orgNum"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :span="12" v-else v-for="(item, index) in form.unitList" :key="index" class="unit-label">
                        <div
                            class="icon"
                            v-if="form.unitList.length > 1 && title !== '查看指标'"
                            @click="deleteDepart(index)"
                        ></div>
                        <el-form-item
                            :label="'责任单位' + `${index + 1}` + '：'"
                            :key="index"
                            :prop="'unitList.' + index + '.orgIdList'"
                            :rules="rules.orgIdList"
                            :label-width="formLabelWidth"
                        >
                            <el-cascader
                                @change="handleChange(index, $event)"
                                ref="cascader"
                                filterable
                                clearable
                                v-model="item.orgIdList"
                                :props="defaultProps"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-if="form.indexType !== 0"> </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-row>
                            <el-col :span="24" v-if="title !== '查看指标'" class="add-unit" @click="addDepartment">
                                + 添加责任单位
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancle">取消</el-button>
                    <el-button type="primary" v-if="title !== '查看指标'" @click="saveItem(ruleForm, 0)"
                        >保存</el-button
                    >
                    <!-- <el-button type="primary" v-if="title !== '查看指标'" @click="saveItem(ruleForm, 0)">提交</el-button> -->
                    <el-button type="primary" v-if="title == '查看指标'" @click="cancle">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';

let dialogFormVisible = ref(true);
const { proxy } = getCurrentInstance();
const envType = computed(() => {
    return process.env.VUE_APP_API_ENV_IRONMENT;
});
const classificationMap = {
    1: '秘密',
    2: '机密',
};
let rules = {
    indexName: [{ required: true, message: '请输入指标名称', trigger: 'blur' }],
    indexType: [{ required: true, message: '请选择是否有目标值', trigger: 'blur' }],
    indexNum: [{ required: true, message: '请填写目标值', trigger: 'blur' }],
    sourceName: [{ required: true, message: '请输入数源应用名称', trigger: 'blur' }],
    whetherSource: [{ required: true, message: '请选择是否有数源应用', trigger: 'blur' }],
    indexSecret: [{ required: true, message: '请选择指标是否涉密', trigger: 'blur' }],
    classification: [{ required: true, message: '请选择指标密级', trigger: 'blur' }],
    orgIdList: [{ required: true, message: '请选择责任单位', trigger: 'blur' }],
    indexType: [{ required: true, message: '请输入目标值', trigger: 'blur' }],
    noIndexType: [{ required: false, message: '请输入目标值', trigger: 'blur' }],
};
let formLabelWidth = '140px';
const form = ref({
    actionYear: new Date().getFullYear(),
    unitList: [{}],
});
let nameOptions = ref([]);
let cascader = ref({});
let taskList = ref([{ actionName: '', levelDeptList: [{}] }]);
let levelDeptList = ref([{}]);
let emit = defineEmits();
let props = defineProps(['title', 'formData']);
const { title, formData } = props;
console.log(title, formData);
if (title !== '新增指标') {
    form.value = formData;
    form.value.indexNumSymbol ? (form.value.indexNumSymbol = Number(form.value.indexNumSymbol)) : '';
    form.value.unitList &&
        form.value.unitList.forEach((e) => {
            e.unitNumSymbol ? (e.unitNumSymbol = Number(e.unitNumSymbol)) : '';
        });
} else {
    form.value.actionId = formData.actionId || '';
}
console.log(form.value);
const defaultProps = {
    lazy: true,
    checkStrictly: true,
    async lazyLoad(node, resolve) {
        // console.log(node,);
        const { level } = node;
        let data = level > 0 ? node.value : 'main';
        level + 1;
        let res = await axios({
            url: '/front/sysOrganization/getByParentExternalId',
            method: 'get',
            params: {
                parentExternalId: data,
            },
        }); //.then(  => {
        if (res) {
            console.log(res);
            let nodes = res.map((item) => {
                return {
                    label: item.organizationName,
                    value: item.externalId,
                    leaf: false,
                    // deptId: item.deptId,
                };
            });
            if (res.length == 0) {
                node.data.leaf = true;
            }
            resolve(nodes);
        }
        // });
    },
};
// 点击联选节点触发事件
function handleChange(index, val) {
    console.log(index, val);
    let nodesInfo = cascader.value[index].getCheckedNodes()[0];
    console.log(nodesInfo);
    form.value.unitList[index].orgName = nodesInfo.data.label;
    form.value.unitList[index].orgId = nodesInfo.data.value;
}
let ruleForm = ref(null);
const saveItem = async (ruleForm, status) => {
    let newForm = Object.assign({}, form.value);
    newForm.status = status;
    if (form.value.unitList) {
        if (0 < form.value.unitList.length < 2) {
            newForm.unitNameAndOrgNum = form.value.unitList[0].orgName;
        } else {
            newForm.unitNameAndOrgNum = form.value.unitList.map((e) => e.orgName).join(',');
        }
    }
    console.log(newForm);
    await ruleForm.validate((valid, fields) => {
        let result = ref(false);
        if (form.value.indexType == 0) {
            let allNum = 0;
            form.value.unitList.map((e) => (allNum += parseInt(e.orgNum)));
            let indexNum = parseInt(form.value.indexNum);
            console.log(allNum, indexNum, form.value.indexNumSymbol);
            switch (form.value.indexNumSymbol) {
                case 0:
                    if (allNum < indexNum) {
                        console.log('大于');
                        result.value = true;
                        ElMessage({
                            message: '责任单位目标值合计小于总体目标值，请重新填写',
                            type: 'warning',
                        });
                        return;
                    }
                    break;
                case 1:
                    if (allNum < indexNum) {
                        console.log('大于等于');
                        result.value = true;
                        ElMessage({
                            message: '责任单位目标值合计小于总体目标值，请重新填写',
                            type: 'warning',
                        });
                        return;
                    }
                    break;
                case 4:
                    console.log('等于');
                    if (allNum < indexNum) {
                        result.value = true;
                        ElMessage({
                            message: '责任单位目标值合计小于总体目标值，请重新填写',
                            type: 'warning',
                        });
                        return;
                    }
                    break;
                case 2:
                    form.value.unitList.forEach((e, index) => {
                        if (e.orgNum > indexNum) {
                            result.value = true;
                            console.log('小于');
                            ElMessage({
                                message: `责任单位${index + 1}目标值大于总体目标值，请重新填写`,
                                type: 'warning',
                            });
                            return;
                        }
                    });
                    break;
                case 3:
                    form.value.unitList.forEach((e, index) => {
                        if (e.orgNum > indexNum) {
                            result.value = true;
                            console.log('小于等于');
                            ElMessage({
                                message: `责任单位${index + 1}目标值大于总体目标值，请重新填写`,
                                type: 'warning',
                            });
                            return;
                        }
                    });
                    break;
                default:
                    break;
            }
        }
        if (!valid || result.value) {
            return;
        }
        // return;
        if (form.value.actionId) {
            if (title == '编辑指标') {
                proxy.$api.frontIndex
                    .update(newForm)
                    .then((res) => {
                        console.log(res);
                        if (res.code == 200) {
                            ElMessage.success('编辑成功');
                            emit('success', {}, newForm.actionId);
                        }
                    })
                    .catch((err) => {
                        // ElMessage.error(err);
                    });
            } else {
                proxy.$api.frontIndex
                    .add(newForm)
                    .then((res) => {
                        if (res.code == 200) {
                            ElMessage.success('新增成功');
                            emit('success', {}, newForm.actionId);
                        }
                    })
                    .catch((err) => {
                        ElMessage.error(err);
                    });
            }
        } else {
            emit('success', newForm);
        }
    });
};

const getById = () => {
    proxy.$api.frontAction.getById(currentTask.data.actionId).then((res) => {
        if (res.code == 200) {
            form.value = res.data;
        }
    });
};
function selectChange(val) {
    form.value.unitList.forEach((e) => {
        e.unitNumSymbol = val;
    });
}
function radioChange(val) {
    if (val) {
        form.value.unitList.forEach((e) => {
            e.orgNum = null;
            e.unitNumSymbol = null;
        });
        form.value.indexNum = null;
        form.value.indexNumSymbol = null;
    }
}

function sourceChange(val) {
    if (val) {
        form.value.sourceName = null;
    }
}

function addDepartment() {
    form.value.unitList.push({});
    if (form.value.indexType == 0) {
        form.value.unitList.forEach((e) => {
            e.unitNumSymbol = form.value.indexNumSymbol;
        });
    }
}
function deleteDepart(index) {
    form.value.unitList.splice(index, 1);
}
let cancle = () => {
    emit('close');
};
function groupInput(val) {
    console.log(val);
    if (val == 1) {
        form.value.classification = '';
    }
}
onMounted(() => {});
</script>

<style scoped lang="stylus">
.form
    padding 16px 18px 24px
    .task
        font-size: 14px;
        color: #333333;
        .title
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 18px
            & > div
                font-weight: 500;
        .input
            background-color: rgba(20, 146, 255, 0.1);
            padding 16px 0 4px
            margin-bottom: 16px
    .addSameTask2
        text-align: center
        font-weight: 400;
        font-size: 14px;
/deep/ .el-dialog__body
    padding: 0 0
/deep/ .el-icon
    vertical-align: -2px
    color: #1492FF
/deep/ .el-cascader
    width 35vw
.add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
        cursor pointer
    .unit-label
        position relative
        display: inline-block
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        left 18px
        z-index 1000
        background: url('~@/assets/img/jian.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: left
/deep/ .el-form-item__content
    flex-wrap:nowrap
</style>
